$duration: 2s;

.commons-loader {
  animation: rotate 2.5s linear infinite;
  z-index: 2;
  margin: -25px 0 0 -25px;
  width: 50px;
  height: 50px;
  color: var(--gold);

  & .commons-loader-path {
    stroke: currentColor;
    stroke-linecap: round;
    animation: dash $duration * .95 ease-in-out infinite;
  }

}

.commons-loader-centered {
  position: absolute;
  top: 50%;
  left: 50%;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 1500;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 900, 1500;
    stroke-dashoffset: -350;
  }
  100% {
    stroke-dasharray: 900, 1500;
    stroke-dashoffset: -1240;
  }
}
