.pomona {
  --blue: hsl(198, 100%, 26%);
  --blue-alpha: hsl(198, 100%, 26%, .5);
  --blue-alpha3: hsl(198, 100%, 26%, .3);
  --blue-alpha7: hsl(198, 100%, 26%, .7);
  --blue-dark: hsl(198, 100%, 22%);
  --blue-light: hsl(198, 100%, 28%);

  --gold: hsl(198, 35%, 50%);
  --gold-alpha: hsl(198, 35%, 50%, .5);
  --gold-dark: hsl(198, 35%, 40%);
  --gold-light: hsl(198, 35%, 70%);

  --red: hsl(11, 76%, 64%);
  --red-alpha: hsl(11, 76%, 64%, .5);
  --red-light: hsl(11, 76%, 74%);
  --red-dark: hsl(11, 76%, 54%);

  --green: hsl(145, 42%, 52%);
  --green-alpha: hsl(145, 42%, 52%, .5);

  --gray: hsl(0, 0%, 50%);

  --primary: var(--blue);
  --secondary: var(--gold);

  a  {
    color: var(--blue);
  }

  .img-logo {
    filter: grayscale(1) brightness(10);
    position: relative;
    top: 10px;
  }

  .topNav {
    background-color: var(--blue) !important;
  }

  .form-check-label.checked::before {
    color: var(--blue);
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: var(--gold);
    background-color: var(--gold);
  }

  .btn-primary {
    border-color: var(--blue);
    background-color: var(--blue);
    color: #fff;

    &:focus {
      box-shadow: inset 0 1px 0 RGB((255 255 255 / 15%)), 0 1px 1px RGB((0 0 0 / 8%)), 0 0 0 0.2rem RGB((83 145 172 / 50%));
    }

    &:hover, &:focus {
      border-color: var(--blue);
      background-color: var(--blue);
      color: #fff;
    }

    &:disabled {
      border-color: var(--blue);
      background-color: var(--blue);
      opacity: 0.5;
    }

    &:not([disabled]):not(.disabled) {
      &:active, &.active {
        border-color: var(--blue-dark);
        background-color: var(--blue-dark);
        box-shadow: none;
        color: #fff;
      }
    }
  }

  .btn-danger {
    border-color: var(--red);
    background-color: var(--red);
    color: #fff;

    &:focus {
      box-shadow: inset 0 1px 0 RGB((255 255 255 / 15%)), 0 1px 1px RGB((0 0 0 / 8%)), 0 0 0 0.2rem RGB((233 118 91 / 50%));
    }

    &:hover, &:focus {
      border-color: var(--red);
      background-color: var(--red);
      color: #fff;
    }

    &:disabled {
      border-color: var(--red);
      background-color: var(--red);
      opacity: 0.5;
    }

    &:not([disabled]):not(.disabled) {
      &:active, &.active {
        border-color: var(--red-dark);
        background-color: var(--red-dark);
        box-shadow: none;
        color: #fff;
      }
    }
  }

  // notifications
  .notifications-wrapper {
    .notification {
      background-color: var(--gold) !important;

      h4 {
        color: #fff !important;
      }

      &.notification-error {
        background-color: var(--red) !important;

        h4 {
          color: #fff !important;
        }
      }
    }
  }

  .item {
    color: var(--blue)
  }

  .item:hover {
    box-shadow: 0 0.125rem 0.25rem RGBA((0, 0, 0, 0.075)), 0 0 0 3px RGBA((83, 145, 172, 0));
    border: 1px solid RGBA((0, 0, 0, 0.125));
    &.item-active {
      box-shadow: 0 0.125rem 0.25rem RGBA((0, 0, 0, 0)), 0 0 0 3px RGBA((83, 145, 172, 0.5));
      border: 1px solid RGBA((0, 0, 0, 0));
    }
  }
}