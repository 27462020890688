.callback-container {
  position:  absolute;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.callback-refresh {
  margin-top: 50px;
}