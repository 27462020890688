@font-face {
  font-family: 'Merlo';
  font-weight: 400;
  src: url('../fonts/merlo/merlo-neue-round-light-webfont.woff2') format('woff2'),
       url('../fonts/merlo/merlo-neue-round-light-webfont.woff') format('woff');
}

@font-face {
  font-family: 'Merlo';
  font-weight: 500;
  src: url('../fonts/merlo/merlo-neue-round-medium-webfont.woff2') format('woff2'),
       url('../fonts/merlo/merlo-neue-round-medium-webfont.woff') format('woff');
}