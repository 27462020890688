.btn
  &:not([disabled]):not(.disabled)
    cursor: pointer

.btn-primary
  border-color: $blue
  border-color: var(--blue, $blue)
  background-color: $blue
  background-color: var(--blue, $blue)

  &:hover
    border-color: $blue-light
    border-color: var(--blue-light, $blue-light)
    background-color: $blue-light
    background-color: var(--blue-light, $blue-light)

  &:not([disabled]):not(.disabled)
    &:active, &.active
      border-color: $blue-dark
      border-color: var(--blue-dark, $blue-dark)
      background-color: $blue-dark
      background-color: var(--blue-dark, $blue-dark)
      box-shadow: none

  &:focus
    box-shadow: none

.btn-link
  color: var(--blue)

  &:hover
    color: var(--blue-light)
    text-decoration: none

  &:active, &:focus
    color: var(--blue-light)
    text-decoration: none

.btn-secondary
  color: $gray-dark
  color: var(--gray-dark, $gray-dark)
  background: none
  border-color: $gray-lighter
  border-color: var(--gray-lighter, $gray-lighter)

  &:not([disabled]):not(.disabled)
    &:hover,
    &:active
      color: $gray-dark
      color: var(--gray-dark, $gray-dark)
      background: none
      box-shadow: none
    &:focus
      background: none
      box-shadow: none
  &.disabled
    background: none
    box-shadow: none
    cursor: not-allowed

.btn.rounded
  border-radius: 38px!important
  padding-left: 30px
  padding-right: 30px

.btn.standard-width
  width: 200px

.btn.btn-link.prec-button
  border: none
  color: $gray-light
  font-size: 16px
  &:hover
    color: $gray-light

.btn-table
  padding: 0
  font-size: inherit
  border-radius: 0
  border: none
  background: none
  color: $gray-lighter

  &:not([disabled]):not(.disabled):active,
  &:not([disabled]):not(.disabled).active
    color: $gray
    background: none
    box-shadow: none

  &:hover
    color: $primary !important