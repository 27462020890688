.home
  padding: 1rem
  display: flex
  flex-wrap: wrap
  .icon
    padding: 0.5rem

.wrapper
  width: 87.5%
  margin-left: auto
  margin-right: auto
  max-width: 1400px

.item
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(146, 106, 20, 0)
  border: 1px solid rgba(0, 0, 0, 0.125)

.item:hover
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(146, 106, 20, 0)
  border: 1px solid rgba(0, 0, 0, 0.125)
  &.item-active
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0), 0 0 0 3px rgba(146, 106, 20, 0.5)
    border: 1px solid rgba(0, 0, 0, 0)