.notifications-wrapper
	position: relative
	z-index: 200000

	.notifications-bl
		bottom: 20px !important
		left: 20px !important

	.notification
		border-radius: 4px !important
		background-color: $white !important
		background-color: var(--white, $white) !important
		box-shadow: 0 0 15px 0 rgba(#2e313d, .2) !important
		border: none !important
		padding: 1.5rem 2rem !important
		height: auto !important

		h4
			margin: 0 !important
			font-weight: normal !important
			color: $green !important
			color: var(--green, $green) !important

		&.notification-error
			h4
				color: $red !important
				color: var(--red, $red) !important

		.notification-dismiss
			display: none